import {
  createGenerateClassName,
  CssBaseline,
  makeStyles,
  MuiThemeProvider,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core'
import React from 'react'

interface ILayout {
  theme: any
}

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: 'themeSppComponent',
})

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
  },
  content: {
    position: 'absolute',
    top: '64px',
    left: '48px',
    width: 'calc(100vw - 48px)',
    height: 'calc(100vh - 64px)',
  },
}))

const Layout: React.FunctionComponent<ILayout> = ({ children, theme }) => {
  const classes = useStyles()

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <main className={classes.content}>{children}</main>
        </div>
      </ThemeProvider>
    </StylesProvider>
  )
}

export default Layout
