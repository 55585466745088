export default {
  en: {
    translation: {
      actions: 'Actions',
      addNotes: 'Add notes',
      addRecommendedSparePartsToCart: 'Add recommended spare parts to cart',
      addRow: 'Add row ',
      addSelectionToCart: 'Add selection to cart',
      addTag: 'Add tag',
      article: 'Article',
      backToParent: 'Back to parent ',
      boms: 'Bill of materials',
      cancel: 'Cancel',
      cartEmpty: 'Your cart is empty',
      component: 'Component',
      createNewTag: 'Create new tag',
      customer: 'Customer',
      customerId: 'Customer id',
      date: 'Date',
      delete: 'Delete',
      description: 'Description',
      disableFull: 'Disable full screen',
      documentPanel: 'Document panel',
      documents: 'Documents',
      documentsPanel: 'Documents panel',
      documentsSelectedNode: 'Documents selected node',
      downloadFile: 'Download file',
      editTag: 'Edit tag',
      electricBom: 'Electric bom',
      enableFull: 'Enable full screen',
      errorSaving: 'Error while saving',
      errorSending: 'Error while sending',
      existTag: 'Existing tag',
      explode: 'Explode',
      firstPage: 'First page',
      goBack: 'Go back',
      goForward: 'Go forward',
      goToRoot: 'Go to root element',
      hideSelectedElements: 'Hide selected elements',
      highlightSpareParts: 'Highligh spare parts',
      infoPanel: 'Info panel',
      infoSelectedNode: 'Info selected node',
      infoSelectedNodes: 'Info selected nodes',
      isolateSelectedElements: 'Isolate selected elements',
      lastPage: 'Last page',
      loading: 'Loading..',
      machine: 'Machine',
      mechanicalBom: 'Mechanical bom',
      name: 'Name',
      newTag: 'New tag',
      nextPage: 'Next page',
      noDataToDisplay: 'No data to display',
      nodeInfoPanel: 'Node info panel',
      noDocuments: 'No documents',
      noOptions: 'No options',
      noRecords: 'No records to display',
      noSelectedNode: 'No selected node',
      number: 'Number',
      openOnStart: 'Open on start',
      orderHistory: 'Order history',
      orderNotSaved: 'Order not saved',
      orderNotSent: 'Order not sent',
      pcs: 'PCs.',
      position: 'Position',
      previousPage: 'Previous page',
      purchaseOrders: 'Purchase Orders',
      qty: 'Qty.',
      quantity: 'Quantity',
      removeItem: 'Remove item',
      rows: 'Rows',
      salesOrder: 'Sales order',
      salesOrders: 'Sales orders',
      save: 'Save',
      search: 'Search',
      selectBusinessPartner: 'Select business partner',
      selectSalesOrder: 'Select sales order',
      selectSerialNumber: 'Select serial number',
      sendOrder: 'Send order request',
      serial: 'Serial',
      serialNumber: 'Serial number',
      serialNumbers: 'Serial numbers',
      settings: 'Settings',
      showAll: 'Show all elements',
      showHiddenElements: 'Show hidden elements',
      showItem: 'Show item',
      sppRequest: 'SPARE PARTS ORDER REQUEST',
      tagValues: 'Tag values',
      total: 'Total',
      treeModel: 'Tree model',
      treePanel: 'Tree panel',
      type: 'Type',
      user: 'User',
      value: 'Value',
      viewerSettings: 'VIEWER SETTINGS',
    },
  },
  it: {
    translation: {
      actions: 'Azioni',
      addNotes: 'Aggiungi note',
      addRecommendedSparePartsToCart:
        'Aggiungi parti di ricambio consigliate al carrello',
      addRow: 'Aggiungi riga',
      addSelectionToCart: 'Aggiungi selezione al carrello',
      addTag: 'Aggiungi tag',
      article: 'Articolo',
      backToParent: 'Ritorna al padre',
      boms: 'Distinta componenti',
      cancel: 'Annulla',
      cartEmpty: 'Il tuo carrello è vuoto',
      component: 'Componente',
      createNewTag: 'Crea nuovo tag',
      customer: 'Cliente',
      customerId: 'Id cliente',
      date: 'Data',
      delete: 'Elimina',
      description: 'Descrizione',
      disableFull: 'Disattiva schermo intero',
      documentPanel: 'Pannello documenti',
      documents: 'Documenti',
      documentsPanel: 'Pannello documenti',
      documentsSelectedNode: 'Documenti selezione',
      downloadFile: 'Scarica file',
      editTag: 'Modifica tag',
      electricBom: 'Bom elettrica',
      enableFull: 'Attiva schermo intero',
      errorSaving: 'Errore durante il salvataggio',
      errorSending: "Errore durante l'invio",
      existTag: 'Tag esistente',
      explode: 'Esplodi',
      firstPage: 'Prima pagina',
      goBack: 'Torna indietro',
      goForward: 'Vai avanti',
      goToRoot: 'Vai al primo elemento',
      hideSelectedElements: 'Nascondi elementi selezionati',
      highlightSpareParts: 'Evidenzia parti di ricambio',
      infoPanel: 'Panello informazioni',
      infoSelectedNode: 'Informazioni selezione',
      infoSelectedNodes: 'Informazioni nodi selezionati',
      isolateSelectedElements: 'Isola elementi selezionati',
      lastPage: 'Ultima pagina',
      loading: 'Caricamento..',
      machine: 'Macchina',
      mechanicalBom: 'Bom meccanica',
      name: 'Nome',
      newTag: 'Tag nuovo',
      nextPage: 'Pagina successiva',
      noDataToDisplay: 'Nessun dato da visualizzare',
      nodeInfoPanel: 'Pannello informazioni selezione',
      noDocuments: 'Nessun documento',
      noOptions: 'Nessuna opzione',
      noRecords: 'Nessun dato da visualizzare',
      noSelectedNode: 'Nessun nodo selezionato',
      number: 'Numero',
      openOnStart: "Apri all'avvio",
      orderHistory: 'Storico ordini',
      orderNotSaved: 'Ordine non salvato',
      orderNotSent: 'Ordine non inviato',
      pcs: 'Pz.',
      position: 'Posizione',
      previousPage: 'Pagina precedente',
      purchaseOrders: 'Richieste di offerta',
      qty: 'Qta.',
      quantity: 'Quantità',
      removeItem: 'Rimuovi elemento',
      rows: 'Righe',
      salesOrder: 'Ordine di vendita',
      salesOrders: 'Ordini di vendita',
      save: 'Salva',
      search: 'Cerca',
      selectBusinessPartner: 'Seleziona business partner',
      selectSalesOrder: 'Seleziona ordine di vendita',
      selectSerialNumber: 'Seleziona numero di serie',
      sendOrder: 'Invia RDO',
      serial: 'Seriale',
      serialNumber: 'Numero di serie',
      serialNumbers: 'Numeri di serie',
      settings: 'Impostazioni',
      showAll: 'Visualizza tutti gli elementi',
      showHiddenElements: 'Mostra elementi nascosti',
      showItem: 'Mostra elemento',
      sppRequest: 'RICHIESTA ORDINE RICAMBI',
      tagValues: 'Valori dei tag',
      total: 'Totale',
      treeModel: 'Albero componenti',
      treePanel: 'Pannello albero componenti',
      type: 'Tipo',
      user: 'Utente',
      value: 'Valore',
      viewerSettings: 'IMPOSTAZIONE DEL VIEWER',
    },
  },
  es: {
    translation: {
      addRecommendedSparePartsToCart:
        'Añadir repuestos recomendados al carrito',
      addSelectionToCart: 'Añadir selección al carrito',
      backToParent: 'Volver al nivel superior',
      cancel: 'Cancelar',
      cartEmpty: 'Carrito vacío',
      customer: 'Cliente',
      date: 'Fecha',
      description: 'Descripción',
      disableFull: 'Desactivar pantalla completa',
      enableFull: 'Habilitar pantalla completa',
      explode: 'Explotar',
      goBack: 'Regresa',
      goForward: 'Avanzar',
      goToRoot: 'Ir al elemento raíz',
      hideSelectedElements: 'Ocultar elementos seleccionados',
      highlightSpareParts: 'Repuestos destacados',
      infoPanel: 'Panel de información',
      infoSelectedNode: 'Información seleccionada nodo',
      isolateSelectedElements: 'Aísla elementos seleccionados',
      loading: 'Cargando..',
      machine: 'Máquina',
      noDataToDisplay: 'No hay información para mostrar',
      orderHistory: 'Historial de pedidos',
      pcs: 'PC',
      previousPage: 'Pagina anterior',
      qty: 'Cant.',
      quantity: 'Cantidad',
      salesOrder: 'Órdenes de venta',
      salesOrders: 'Ordenes de venta',
      search: 'Buscar',
      serialNumber: 'Número de serie',
      serialNumbers: 'Números de serie',
      settings: 'Configuracion',
      showAll: 'Mostrar todos los elementos',
      showHiddenElements: 'Mostrar elementos ocultos',
      total: 'Total',
      treeModel: 'Modelo de árbol',
    },
  },
}
