import React, { useContext, useEffect } from 'react'
import { gql, StoreObject, useLazyQuery, useMutation } from '@apollo/client'
import { isArray } from 'lodash'
import { ICartItem, ICart } from '@NKE/utils'
import { updateCart as navbarUpdateCart } from '@NKE/utils'
import { useUserInfo } from '@nke/spp-components.context.user-info'

interface ICartQuery {
  purchaseOrdersQuery: {
    cart: ICart
  }
}

interface ICartUpdate {
  purchaseOrdersMutation: {
    updateCart: StoreObject
  }
}

interface ICartItemUpdate {
  purchaseOrdersMutation: {
    updateCartItem: StoreObject
  }
}

interface ICartItemCreate {
  purchaseOrdersMutation: {
    createCartItem: StoreObject
  }
}

interface ICartCreate {
  purchaseOrdersMutation: {
    createCart: StoreObject
  }
}

interface ICartDelete {
  purchaseOrdersMutation: {
    deleteCartItem: StoreObject
  }
}

interface ICartItemInput {
  id: number
  cartId: number
  componentId: number
  componentCode: string
  componentDescription: string
  quantity: number
  serialNumberId: number
  revBomId: number
  img: string
  dbId: number
  relationId: string
  machineUrl: string
}

interface ICartInput {
  userId: number
  notes: string
}

const GET = gql`
  query {
    purchaseOrdersQuery {
      cart {
        id
        userId
        notes
        cartItems {
          id
          componentId
          componentCode
          componentDescription
          quantity
          serialNumberId
          revBomId
          img
          dbId
          relationId
          machineUrl
          serialNumber {
            number
            salesOrder
          }
        }
      }
    }
  }
`

const POST = gql`
  mutation updateCartItem($cartItem: CartItemInput!) {
    purchaseOrdersMutation {
      createCartItem(cartItem: $cartItem) {
        id
      }
    }
  }
`

const POST_CART = gql`
  mutation createCart($cart: CartInput!) {
    purchaseOrdersMutation {
      createCart(cart: $cart) {
        id
        userId
        notes
        cartItems {
          id
          componentId
          componentCode
          componentDescription
          quantity
          serialNumberId
          revBomId
          img
          dbId
          relationId
          machineUrl
          serialNumber {
            number
            salesOrder
          }
        }
      }
    }
  }
`

const UPDATE = gql`
  mutation updateCartItem($id: Int!, $cartItem: CartItemInput!) {
    purchaseOrdersMutation {
      updateCartItem(id: $id, cartItem: $cartItem) {
        id
      }
    }
  }
`

const UPDATE_CART = gql`
  mutation updateCart($id: Int!, $cart: CartInput!) {
    purchaseOrdersMutation {
      updateCart(id: $id, cart: $cart) {
        id
      }
    }
  }
`

const DELETE = gql`
  mutation updateCartItem($id: Int!) {
    purchaseOrdersMutation {
      deleteCartItem(id: $id) {
        id
      }
    }
  }
`

const findById: (
  items: ICartItem[],
  componentdId: number,
  serialNumberId: number
) => ICartItem | null = (
  items: ICartItem[],
  componentId: number,
  serialNumberId: number
) => {
  let _res = null

  items.forEach(item => {
    if (
      item.componentId === componentId &&
      item.serialNumberId === serialNumberId
    ) {
      _res = item
    }
  })

  return _res
}

interface ICartItemsContext {
  cart: ICart
  setCart: (cart: ICart) => void
  reloadCartItems: () => void
  updateCartItems: (items: ICartItem[] | ICartItem) => void
  addCartItems: (items: ICartItem[]) => void
  removeCartItems: (items: ICartItem) => void
  updateCartValue: (cart: ICart) => void
  loadingCart: boolean
}

const defaultCart: ICart = {
  userId: 0,
  notes: undefined,
  cartItems: [],
}

const CartItemsContext = React.createContext<ICartItemsContext>({
  cart: defaultCart,
  setCart: () => {},
  reloadCartItems: () => {},
  updateCartItems: () => {},
  addCartItems: () => {},
  removeCartItems: () => {},
  updateCartValue: () => {},
  loadingCart: false,
})

type Props = {
  children: React.ReactNode
}

const CartItemsContextProvider = ({ children }: Props) => {
  const [cart, setCart] = React.useState<ICart>(defaultCart)

  const userInfo = useUserInfo()

  const [updateCartItem] = useMutation<
    ICartItemUpdate,
    { id: number; cartItem: ICartItemInput }
  >(UPDATE, {
    onCompleted: () => getCartItems(),
  })
  const [updateCart] = useMutation<
    ICartUpdate,
    { id: number; cart: ICartInput }
  >(UPDATE_CART, {
    onCompleted: () => getCartItems(),
  })

  const [createCartItem] = useMutation<
    ICartItemCreate,
    { cartItem: ICartItemInput }
  >(POST, {
    onCompleted: () => getCartItems(),
  })
  const [createCart] = useMutation<ICartCreate, { cart: ICartInput }>(POST_CART)

  const [deleteCartItem] = useMutation<ICartDelete, { id: number }>(DELETE, {
    onCompleted: () => getCartItems(),
  })

  const [getCartItems, { loading }] = useLazyQuery<ICartQuery>(GET, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data?.purchaseOrdersQuery.cart) {
        setCart(JSON.parse(JSON.stringify(data.purchaseOrdersQuery.cart)))
      } else {
        setCart(defaultCart)
      }
    },
    onError: () => setCart(defaultCart),
  })

  useEffect(() => {
    getCartItems()
  }, [])

  React.useEffect(() => {
    navbarUpdateCart(cart.cartItems.length)
  }, [cart])

  const reloadCartItems = () => {
    getCartItems()
  }

  const updateCartItems = async (items: ICartItem[] | ICartItem) => {
    let _cartId = cart.id
    if (!_cartId) {
      _cartId = await createNewCart()
    }

    if (isArray(items)) {
      ;(items as ICartItem[]).forEach(item => {
        updateCartItem({
          variables: {
            id: item.id ?? 0,
            cartItem: {
              id: item.id ?? 0,
              cartId: _cartId,
              componentId: item.componentId,
              componentCode: item.componentCode,
              componentDescription: item.componentDescription ?? '',
              quantity: item.quantity,
              serialNumberId: item.serialNumberId,
              revBomId: item.revBomId,
              img: item.img,
              dbId: item.dbId,
              relationId: item.relationId,
              machineUrl: item.machineUrl.replace('/spp', ''),
            },
          },
        })
      })
    } else {
      const _item = items as ICartItem
      updateCartItem({
        variables: {
          id: _item.id ?? 0,
          cartItem: {
            id: _item.id ?? 0,
            cartId: _cartId,
            componentId: _item.componentId,
            componentCode: _item.componentCode,
            componentDescription: _item.componentDescription ?? '',
            quantity: _item.quantity,
            serialNumberId: _item.serialNumberId,
            revBomId: _item.revBomId,
            img: _item.img,
            dbId: _item.dbId,
            relationId: _item.relationId,
            machineUrl: _item.machineUrl.replace('/spp', ''),
          },
        },
      })
    }
  }

  const createNewCart = async (notes: string = '') => {
    const { data } = await createCart({
      variables: {
        cart: {
          userId: parseInt(userInfo.loginId),
          notes,
        },
      },
    })

    const _cart = data.purchaseOrdersMutation.createCart
    setCart({
      id: _cart.id as number,
      userId: _cart.userId as number,
      notes: _cart.notes as string,
      cartItems: [] as ICartItem[],
    })

    return _cart.id as number
  }

  const addCartItems = async (newCartItems: ICartItem[]) => {
    let _cartId = cart.id
    if (!_cartId) {
      _cartId = await createNewCart()
    }

    const _cartItems = cart.cartItems.map(cartItem => ({ ...cartItem }))
    newCartItems.forEach(item => {
      const _check = findById(_cartItems, item.componentId, item.serialNumberId)
      if (!_check) {
        item.quantity = item.quantity ?? 1
        createCartItem({
          variables: {
            cartItem: {
              id: item.id ?? 0,
              cartId: _cartId,
              componentId: item.componentId,
              componentCode: item.componentCode,
              componentDescription: item.componentDescription ?? '',
              quantity: item.quantity,
              serialNumberId: item.serialNumberId,
              revBomId: item.revBomId,
              img: item.img,
              dbId: item.dbId,
              relationId: item.relationId,
              machineUrl: item.machineUrl.replace('/spp', ''),
            },
          },
        })
      } else if (_check) {
        _check.quantity = _check.quantity + (item.quantity ?? 1)

        updateCartItem({
          variables: {
            id: _check.id ?? 0,
            cartItem: {
              id: _check.id ?? 0,
              cartId: _cartId,
              componentId: _check.componentId,
              componentCode: _check.componentCode,
              componentDescription: _check.componentDescription ?? '',
              quantity: _check.quantity,
              serialNumberId: _check.serialNumberId,
              revBomId: _check.revBomId,
              img: _check.img,
              dbId: _check.dbId,
              relationId: _check.relationId,
              machineUrl: _check.machineUrl.replace('/spp', ''),
            },
          },
        })
      }
    })
  }

  const removeCartItems = (deleteItem: ICartItem) => {
    const _cartItems = cart.cartItems.map(cartItem => ({ ...cartItem }))
    const _updatedItems = _cartItems.filter(val => {
      const ret =
        val.componentId === deleteItem.componentId &&
        val.serialNumberId === deleteItem.serialNumberId

      return ret
    })

    _updatedItems.forEach(item => {
      deleteCartItem({
        variables: {
          id: item.id ?? 0,
        },
      })
    })
  }

  const updateCartValue = (cart: ICart) => {
    if (cart.id) {
      updateCart({
        variables: {
          id: cart.id,
          cart: {
            userId: cart.userId,
            notes: cart.notes,
          },
        },
      })
    } else {
      createNewCart(cart.notes)
    }
  }

  return (
    <CartItemsContext.Provider
      value={{
        cart,
        setCart,
        reloadCartItems,
        updateCartItems,
        addCartItems,
        removeCartItems,
        updateCartValue,
        loadingCart: loading,
      }}
    >
      {children}
    </CartItemsContext.Provider>
  )
}

const useCartItems = () => React.useContext(CartItemsContext)

export { CartItemsContext, CartItemsContextProvider, useCartItems }
