import React from 'react'
import CubeLoader from './cube_loader'

const AppLoader = () => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        top: 0,
        left: 0,
      }}
    >
      <div style={{ margin: 'auto' }}>
        <CubeLoader />
      </div>
    </div>
  )
}

export default AppLoader
