import { getSppClient, theme } from '@NKE/utils'
import React, { Suspense } from 'react'
import { ApolloProvider } from '@apollo/client'
import Layout from './components/layout'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { UserInfoProvider } from '@nke/spp-components.context.user-info'
import './styles/index.scss'
import './i18next'

import {
  FullScreenContextProvider,
  CartItemsContextProvider,
  SettingsContextProvider,
  LogoContextProvider,
} from './context'
import { AppLoader } from './components/loader'

export default function Root(props) {
  const LazySPP = React.lazy(() => import('./spp'))
  return (
    <Layout theme={theme}>
      <ApolloProvider client={getSppClient()}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <LogoContextProvider>
            <UserInfoProvider token={localStorage.getItem('token') ?? ''}>
              <FullScreenContextProvider>
                <CartItemsContextProvider>
                  <SettingsContextProvider>
                    {localStorage.getItem('token') ? (
                      <Suspense fallback={<AppLoader />}>
                        <LazySPP />
                      </Suspense>
                    ) : (
                      <div />
                    )}
                  </SettingsContextProvider>
                </CartItemsContextProvider>
              </FullScreenContextProvider>
            </UserInfoProvider>
          </LogoContextProvider>
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </Layout>
  )
}
